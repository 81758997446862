let Environment = {
  StakePoolBNBForBNB: '0x82687d315B488b347177924b0cD5C0c3188Bc350',
  StakePoolBNBForToken: '0x68744fAbc21888908a9fe7CacCE777CDc9ba76b4',
  StakePoolTokenForBNB: '0x288C82A97ac12e601e59f45A7CC45746b6366045',
  StakePoolTokenForToken: '0xfDf47320dC8bA542C2Bb90799649b82B07B59B59',

  VStakePoolBNBForBNB: '0x2d7163E890ABe032b745e05802aF35442B55235C',
  VStakePoolBNBForToken: '0x0bCe5646710F09c8CE39FC44854bD3c9c9E731a5',
  VStakePoolTokenForBNB: '0x1C0835a58316AA02506460902C2be156da3Bc419',
  VStakePoolTokenForToken: '0xdE5EaCB49AC5833c44C1285431165eFf994034EE',
};

export default Environment;
