import '../../App.scss';
import { API_URL } from '../../utils/ApiURL';
import Footer from './footer/Footer';
import Navbar from './header/Navbar';
import Banner from './main-banner/Banner';
import axios from 'axios';
import { useEffect, useState } from 'react';
const Landing=()=> {
  const [colorData, setColorData] = useState(undefined);
  const poolColors = async () => {
    try {
      const res = await axios.get(`${API_URL}project/getAllProjectDetails`)
      setColorData(res?.data?.data[0])
    } catch (error) {

    }
  }
  useEffect(() => {
    poolColors()
  }, [])

  return (
    <>
    
    <div style={{ backgroundColor: `${colorData?.bodyColor}` }}>
      <Navbar colorData={colorData} />
      <Banner colorData={colorData} />
      <Footer colorData={colorData} />
    </div>
    </>
  );
}

export default Landing;